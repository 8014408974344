import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import visibilityIcon from '@/components/Icons/VisibilityIcon.vue'
import visibilityOffIcon from '@/components/Icons/VisibilityOffIcon.vue'
import checkCircleIcon from '@/components/Icons/CheckCircleIcon.vue'
import alertWarningIcon from '@/components/Icons/AlertWarningIcon.vue'
import snackBarErrorIcon from '@/components/Icons/SnackBarErrorIcon.vue'
import snackBarSuccessIcon from '@/components/Icons/SnackBarSuccessIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: 'md',
		values: {
			opVisibility: { component: visibilityIcon },
			opVisibilityOff: { component: visibilityOffIcon },
			opCheckCircle: { component: checkCircleIcon },
			opWarning: { component: alertWarningIcon },
			opSnackBarError: { component: snackBarErrorIcon },
			opSnackBarSuccess: { component: snackBarSuccessIcon }
		}
	},
	theme: {
		themes: {
			light: {
				error: '#CC1D33'
			}
		}
	}
})
