<template>
	<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 4.09091C9.27668 4.09091 8.58299 4.37825 8.07153 4.88971C7.56006 5.40117 7.27273 6.09486 7.27273 6.81818C7.27273 7.5415 7.56006 8.23519 8.07153 8.74666C8.58299 9.25812 9.27668 9.54545 10 9.54545C10.7233 9.54545 11.417 9.25812 11.9285 8.74666C12.4399 8.23519 12.7273 7.5415 12.7273 6.81818C12.7273 6.09486 12.4399 5.40117 11.9285 4.88971C11.417 4.37825 10.7233 4.09091 10 4.09091ZM10 11.3636C8.79447 11.3636 7.63832 10.8847 6.78588 10.0323C5.93344 9.17987 5.45455 8.02371 5.45455 6.81818C5.45455 5.61265 5.93344 4.4565 6.78588 3.60406C7.63832 2.75162 8.79447 2.27273 10 2.27273C11.2055 2.27273 12.3617 2.75162 13.2141 3.60406C14.0666 4.4565 14.5455 5.61265 14.5455 6.81818C14.5455 8.02371 14.0666 9.17987 13.2141 10.0323C12.3617 10.8847 11.2055 11.3636 10 11.3636ZM10 0C5.45455 0 1.57273 2.82727 0 6.81818C1.57273 10.8091 5.45455 13.6364 10 13.6364C14.5455 13.6364 18.4273 10.8091 20 6.81818C18.4273 2.82727 14.5455 0 10 0Z"
			fill="#999999"
		/>
	</svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'VisibilityIcon'
})
</script>
