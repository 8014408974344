<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="36" height="36" rx="18" fill="#E00000" />
		<path
			d="M17.9999 19.1288C18.2274 19.1288 18.4157 19.0542 18.5648 18.9051C18.7132 18.7567 18.7874 18.5688 18.7874 18.3413V13.59C18.7874 13.38 18.7132 13.2005 18.5648 13.0514C18.4157 12.903 18.2274 12.8288 17.9999 12.8288C17.7724 12.8288 17.5845 12.903 17.4361 13.0514C17.287 13.2005 17.2124 13.3888 17.2124 13.6163V18.3675C17.2124 18.5775 17.287 18.7567 17.4361 18.9051C17.5845 19.0542 17.7724 19.1288 17.9999 19.1288ZM17.9999 22.9613C18.2449 22.9613 18.4462 22.8825 18.6037 22.725C18.7612 22.5675 18.8399 22.3663 18.8399 22.1213C18.8399 21.8763 18.7612 21.675 18.6037 21.5175C18.4462 21.36 18.2449 21.2813 17.9999 21.2813C17.7549 21.2813 17.5537 21.36 17.3962 21.5175C17.2387 21.675 17.1599 21.8763 17.1599 22.1213C17.1599 22.3663 17.2387 22.5675 17.3962 22.725C17.5537 22.8825 17.7549 22.9613 17.9999 22.9613ZM17.9999 27.975C16.6174 27.975 15.3182 27.7125 14.1023 27.1875C12.8857 26.6625 11.8312 25.9538 10.9387 25.0613C10.0462 24.1688 9.3374 23.1142 8.8124 21.8976C8.2874 20.6817 8.0249 19.3825 8.0249 18C8.0249 16.6175 8.2874 15.318 8.8124 14.1014C9.3374 12.8855 10.0462 11.8313 10.9387 10.9388C11.8312 10.0463 12.8857 9.33752 14.1023 8.81252C15.3182 8.28752 16.6174 8.02502 17.9999 8.02502C19.3824 8.02502 20.682 8.28752 21.8986 8.81252C23.1145 9.33752 24.1687 10.0463 25.0612 10.9388C25.9537 11.8313 26.6624 12.8855 27.1874 14.1014C27.7124 15.318 27.9749 16.6175 27.9749 18C27.9749 19.3825 27.7124 20.6817 27.1874 21.8976C26.6624 23.1142 25.9537 24.1688 25.0612 25.0613C24.1687 25.9538 23.1145 26.6625 21.8986 27.1875C20.682 27.7125 19.3824 27.975 17.9999 27.975ZM17.9999 26.4C20.3274 26.4 22.3095 25.5821 23.9461 23.9462C25.582 22.3096 26.3999 20.3275 26.3999 18C26.3999 15.6725 25.582 13.6905 23.9461 12.0539C22.3095 10.418 20.3274 9.60002 17.9999 9.60002C15.6724 9.60002 13.6907 10.418 12.0548 12.0539C10.4182 13.6905 9.5999 15.6725 9.5999 18C9.5999 20.3275 10.4182 22.3096 12.0548 23.9462C13.6907 25.5821 15.6724 26.4 17.9999 26.4Z"
			fill="white"
		/>
	</svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'SnackBarErrorIcon'
})
</script>
