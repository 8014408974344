
import Vue from 'vue'
import Cookies from 'js-cookie'
import { useStore } from './store'
import { mapStores } from 'pinia'

const OP_DOMAIN_PATH = process.env['VUE_APP_OP_DOMAIN']
const COOKIE_EXPIRES_IN_DAYS = 360
const COOKIE_DOMAIN_PATH = `.${OP_DOMAIN_PATH}`

export default Vue.extend({
	data() {
		return {
			localeLabels: { en: 'ENG', id: 'IND' } as Record<string, string>,
			btnLinkAttr: {
				ripple: false,
				xLarge: true,
				text: true,
				plain: true,
				maxHeight: 32,
				maxWidth: '100%',
				minWidth: 'fit-content'
			},
			selectedLangIndex: 0 as number
		}
	},
	computed: {
		...mapStores(useStore),
		locale(): string {
			return this.$i18n.locale
		},
		homeUrl(): string {
			return `https://www.${OP_DOMAIN_PATH}`
		},
		localeLabel(): string {
			return this.localeLabels[this.locale]
		},
		localeIcon(): string {
			return this.getIconUrl(this.locale)
		},
		isPasswordChangesPage(): boolean {
			const { path = '' } = this.$route
			return path.endsWith('password')
		},
		backButtonAttr() {
			return this.isPasswordChangesPage ? { to: { name: 'signin' } } : { href: this.homeUrl }
		},
		backButtonLabel(): string {
			return this.isPasswordChangesPage ? 'buttons.back_to_signin' : 'buttons.homepage'
		},
		isRendered(): boolean {
			return this.mainStore.reRender
		}
	},
	beforeMount() {
		const locale = (Cookies.get('LANG') as string) || 'id'
		this.selectedLangIndex = Object.keys(this.localeLabels).indexOf(locale)
		this.setLocale(locale)
		this.setLanguage(locale)
	},
	watch: {
		'$route.query.redirect_url': {
			handler: function setRedirectUrl(url: string) {
				this.$gsi.setGoogleContext(this.$route.name === 'signup' ? 'signup' : 'signin')

				if (url && url !== '') {
					const newUrl = url + this.$route.hash
					this.$gsi.setRedirectUrl(newUrl)
					this.mainStore.setRedirectUrl(newUrl)
				}
				this.$gsi.reInitialize()
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		getIconUrl(id: string): string {
			return require(`@/assets/flag_${id}.svg`)
		},
		setLocale(locale: string) {
			this.$root.$i18n.locale = locale
			this.$i18n.locale = locale
		},
		setLanguage(locale: string) {
			Cookies.set('LANG', locale, {
				expires: COOKIE_EXPIRES_IN_DAYS,
				domain: COOKIE_DOMAIN_PATH
			})
			this.setLocale(locale)
			this.setPageTitle()
		},
		setPageTitle() {
			const lang = this.$i18n.locale
			const title = this.$route.meta?.title[lang]
			document.title = `${title} - OnlinePajak`
		}
	}
})
