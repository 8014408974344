import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'
import en from '@/locales/en.json'
import id from '@/locales/id.json'

Vue.use(VueI18n)

const i18n = createI18n({
	locale: 'id',
	fallbackLocale: 'en',
	messages: {
		en,
		id
	}
})

export default i18n
