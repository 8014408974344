<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.3836 5.62594C14.8802 2.12469 9.13097 2.12469 5.62756 5.62594C2.12415 9.12718 2.12415 14.8728 5.62756 18.3741C9.13097 21.8753 14.8802 21.8753 18.2937 18.3741C21.887 14.8728 21.887 9.12718 18.3836 5.62594ZM10.2988 16.7581L6.43604 12.8978L7.69367 11.6409L10.2988 14.2444L16.6768 7.87032L17.9344 9.12718L10.2988 16.7581Z"
			fill="#2AB654"
		/>
	</svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'CheckCircleIcon'
})
</script>
