import { render, staticRenderFns } from "./PageAction.vue?vue&type=template&id=323b5d94&scoped=true"
import script from "./PageAction.vue?vue&type=script&lang=ts"
export * from "./PageAction.vue?vue&type=script&lang=ts"
import style0 from "./PageAction.vue?vue&type=style&index=0&id=323b5d94&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323b5d94",
  null
  
)

export default component.exports