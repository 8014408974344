
import Vue from 'vue'

export default Vue.extend({
	props: {
		block: {
			type: Boolean,
			default: false
		},
		text: {
			type: String,
			default: 'Button'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		pill: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		action(evt: MouseEvent) {
			this.$emit('click', evt)
		}
	}
})
