const getHostName = () => {
	let hostName = null
	if (process.env.VUE_APP_OP_DOMAIN) {
		hostName = process.env.VUE_APP_OP_DOMAIN
	}
	return hostName
}

export function domainBuilder(subDomain = 'auth') {
	const hostName = getHostName()
	if (hostName) return `https://${subDomain}.${hostName}`
	else return '/mock/' + subDomain
}
