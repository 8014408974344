
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
	props: {
		type: {
			type: String,
			default: 'signin'
		}
	},
	computed: {
		links(): Record<string, string> {
			return {
				signin: 'signup',
				signup: 'signin'
			}
		},
		texts(): Record<string, TranslateResult> {
			return {
				signin: this.$t('text.signin'),
				signup: this.$t('text.signup')
			}
		},
		actions(): Record<string, TranslateResult> {
			return {
				signin: this.$t('action.signup'),
				signup: this.$t('action.signin')
			}
		}
	}
})
