<template>
	<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" width="36" height="36" rx="18" fill="#2AB654" />
		<path
			d="M17.0037 20.2575L14.5362 17.79C14.3962 17.65 14.217 17.5845 13.9986 17.5936C13.7795 17.602 13.5999 17.6762 13.4599 17.8162C13.3024 17.9737 13.2237 18.1575 13.2237 18.3675C13.2237 18.5775 13.3024 18.7612 13.4599 18.9187L16.3474 21.8062C16.5224 21.9812 16.7412 22.0687 17.0037 22.0687C17.2662 22.0687 17.4937 21.9812 17.6862 21.8062L23.5399 15.9262C23.6799 15.7862 23.7457 15.6067 23.7373 15.3876C23.7282 15.1692 23.6537 14.99 23.5137 14.85C23.3562 14.6925 23.1724 14.6137 22.9624 14.6137C22.7524 14.6137 22.5687 14.6925 22.4112 14.85L17.0037 20.2575ZM18.4999 27.975C17.1174 27.975 15.8182 27.7125 14.6023 27.1875C13.3857 26.6625 12.3312 25.9537 11.4387 25.0612C10.5462 24.1687 9.8374 23.1142 9.3124 21.8976C8.7874 20.6817 8.5249 19.3825 8.5249 18C8.5249 16.6175 8.7874 15.3179 9.3124 14.1013C9.8374 12.8854 10.5462 11.8312 11.4387 10.9387C12.3312 10.0462 13.3857 9.33749 14.6023 8.81249C15.8182 8.28749 17.1174 8.02499 18.4999 8.02499C19.8824 8.02499 21.182 8.28749 22.3986 8.81249C23.6145 9.33749 24.6687 10.0462 25.5612 10.9387C26.4537 11.8312 27.1624 12.8854 27.6874 14.1013C28.2124 15.3179 28.4749 16.6175 28.4749 18C28.4749 19.3825 28.2124 20.6817 27.6874 21.8976C27.1624 23.1142 26.4537 24.1687 25.5612 25.0612C24.6687 25.9537 23.6145 26.6625 22.3986 27.1875C21.182 27.7125 19.8824 27.975 18.4999 27.975ZM18.4999 26.4C20.8274 26.4 22.8095 25.582 24.4461 23.9461C26.082 22.3095 26.8999 20.3275 26.8999 18C26.8999 15.6725 26.082 13.6904 24.4461 12.0538C22.8095 10.4179 20.8274 9.59999 18.4999 9.59999C16.1724 9.59999 14.1907 10.4179 12.5548 12.0538C10.9182 13.6904 10.0999 15.6725 10.0999 18C10.0999 20.3275 10.9182 22.3095 12.5548 23.9461C14.1907 25.582 16.1724 26.4 18.4999 26.4Z"
			fill="white"
		/>
	</svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'SnackBarSuccessIcon'
})
</script>
