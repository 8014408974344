
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { validateEmail, validatePassword } from '@/utils/validator'
import { domainBuilder } from '@/utils/domainBuilder'
import { getQueryParam } from '@/utils/url'
import AuthService from '@/services'
import AnalyticsService from '@/services/analytics'
import PageTitle from '@/components/PageTitle.vue'
import PageAction from '@/components/PageAction.vue'
import FormDivider from '@/components/FormDivider.vue'
import TextField from '@/components/TextField.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import AlertBanner from '@/components/AlertBanner.vue'
import TermsConditions from '@/components/TermsConditions.vue'
import OpDialog from '@/components/OpDialog.vue'
import {
	EVENT_LABEL_EmailLoginSuccess,
	EVENT_LABEL_LOGIN_FORM,
	EVENT_LABEL_LOCATION,
	EVENT_LABEL_LOGIN,
	EVENT_LABEL_EMAIL_METHOD,
	EVENT_LABEL_GOOGLE_METHOD,
	UI_DELAY
} from '@/constants'

const GA4service = new AnalyticsService()

const HUBSPOT_PORTAL_ID = process.env.VUE_APP_HUBSPOT_PORTAL_ID
const HUBSPOT_LIB_URL = process.env.VUE_APP_HUBSPOT_LIB_URL
const HUBSPOT_FORM_ID = process.env.VUE_APP_HUBSPOT_BLOCK_USER

const delay = () => new Promise((resolve) => setTimeout(resolve, UI_DELAY))

export default defineComponent({
	components: {
		PageTitle,
		PageAction,
		FormDivider,
		TextField,
		PrimaryButton,
		AlertBanner,
		TermsConditions,
		OpDialog
	},
	props: {
		isWebComponent: {
			type: Boolean,
			default: false
		},
		redirectUrl: {
			type: String,
			default: ''
		},
		showExpiredMessage: {
			type: String,
			default: null
		},
		lang: {
			type: String,
			default: 'id'
		},
		companyNpwp: {
			type: String,
			default: ''
		}
	},
	setup() {
		const store = useStore()
		return { store }
	},
	data() {
		return {
			attempt: 0,
			form: {
				email: '',
				password: '',
				redirectUrl: '',
				companyNpwp: ''
			},
			errors: {
				email: '',
				password: ''
			},
			apiErrorMessage: '',
			forgotPasswordLink: domainBuilder('auth'),
			isDialogOpen: false,
			userDomain: getQueryParam('domain')
		}
	},
	computed: {
		isApiError(): boolean {
			return !!this.apiErrorMessage
		},
		isErrorEmail(): boolean {
			return !!this.errors.email
		},
		isErrorPassword(): boolean {
			return !!this.errors.password
		},
		isRendered(): boolean {
			return this.store.reRender
		}
	},
	mounted() {
		if (!!this.showExpiredMessage && this.showExpiredMessage === 'true') {
			this.apiErrorMessage = 'errors.token_expired'
			const query = Object.assign({}, this.$route.query)
			delete query.show_expired_message
			this.$router.replace({ query })
		}

		if (getQueryParam('blocked_user') === 'true') {
			this.handleOpenDialog()
		}

		this.catchGoogleSSOError()
		if (this.isWebComponent) {
			const locationUrl = window.location.href
			let location = EVENT_LABEL_LOCATION.default
			if (locationUrl.indexOf('gated-content') !== -1) {
				location = EVENT_LABEL_LOCATION.gatedContent
			}
			this.$i18n.locale = this.lang
			this.$gsi.setRedirectUrl(this.redirectUrl)
			this.$gsi.setSource(location)
			this.$gsi.reInitialize()
			this.$gsi.createWCButton(this.$refs.signInButtonRef, this.$i18n.locale, 'continue_with')
		} else {
			this.$gsi.createButton(this.$refs.signInButtonRef, this.$i18n.locale)
		}
	},
	watch: {
		'$i18n.locale': {
			async handler(newLocale) {
				this.store.isRendered(false)
				if (this.isWebComponent) {
					this.$gsi.createWCButton(
						this.$refs.signInButtonRef,
						this.$i18n.locale,
						'continue_with'
					)
				} else {
					this.$gsi.createButton(this.$refs.signInButtonRef, newLocale)
				}
				await delay()
				this.$forceUpdate()
				this.store.isRendered(true)
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		async handleOpenDialog() {
			const script = document.createElement('script')
			script.src = HUBSPOT_LIB_URL
			document.body.appendChild(script)
			script.addEventListener('load', async () => {
				if (window.hbspt) {
					await window.hbspt.forms.create({
						region: 'na1',
						portalId: HUBSPOT_PORTAL_ID,
						formId: HUBSPOT_FORM_ID,
						target: '#hubspotForm-user-unpermitted-signin'
					})
				}
			})
			this.isDialogOpen = true
		},
		async handleCloseDialog() {
			this.isDialogOpen = false
			this.$router.replace({ query: null })
		},
		catchGoogleSSOError() {
			const googleErrorProvide = ['attempt', 'user-not-found', 'user-already-registered']

			const errorHashMessage = (this.$route.query['google-failure'] as string) || ''

			if (googleErrorProvide.includes(errorHashMessage)) {
				this.apiErrorMessage = `errors.${errorHashMessage}`

				GA4service.sendGa4NewFormatEvent({
					event: `authentication.${EVENT_LABEL_LOGIN}`,
					event_label: EVENT_LABEL_LOGIN,
					userId: null as any,
					lang: this.$i18n.locale,
					auth_method: EVENT_LABEL_GOOGLE_METHOD,
					status: 'fail',
					message: this.$t(this.apiErrorMessage) as string,
					location: 'platform_auth'
				})
				setTimeout(() => this.$router.replace({ query: null as any }), 9600)
			}
		},
		setEmail(val: string) {
			this.form.email = val
			this.validateForm()
		},
		setPassword(val: string) {
			this.form.password = val
			this.validateForm()
		},

		validateForm() {
			this.apiErrorMessage = ''

			if (!this.form.email.length) this.errors.email = 'errors.email__required'
			else if (!validateEmail(this.form.email)) this.errors.email = 'errors.email__invalid'
			else this.errors.email = ''

			if (!this.form.password.length) this.errors.password = 'errors.password__required'
			else if (!validatePassword(this.form.password))
				this.errors.password = 'errors.password__invalid-length'
			else this.errors.password = ''
		},

		async signInAction() {
			const locationUrl = window.location.href
			let location = EVENT_LABEL_LOCATION.default
			if (locationUrl.indexOf('gated-content') !== -1) {
				location = EVENT_LABEL_LOCATION.gatedContent
			}
			this.validateForm()
			this.attempt = 0

			this.form.redirectUrl = this.store.redirectUrl
				? this.store.redirectUrl
				: this.redirectUrl

			this.form.companyNpwp = this.companyNpwp

			if (!this.isErrorEmail && !this.isErrorPassword && !this.isApiError) {
				try {
					const { redirectUrl, user_id } = await AuthService.signInAction(this.form)
					GA4service.sendGA4EventMarketing({
						event: EVENT_LABEL_EmailLoginSuccess,
						formId: EVENT_LABEL_LOGIN_FORM,
						userId: user_id,
						location
					})
					GA4service.sendGa4NewFormatEvent({
						event: `authentication.${EVENT_LABEL_LOGIN}`,
						event_label: EVENT_LABEL_LOGIN,
						userId: user_id,
						lang: this.$i18n.locale,
						auth_method: EVENT_LABEL_EMAIL_METHOD,
						status: 'success',
						message: '',
						location,
						eventCallback: () => {
							window.location.href = redirectUrl
						}
					})
				} catch (err) {
					const failState = err.response?.data?.failState
					this.attempt = err.response?.data?.loginChance

					let message = ''
					if (failState === 'user') {
						const blockRedirect = err.response?.data?.redirectUrl
						if (blockRedirect) {
							window.location.href = blockRedirect
						} else {
							this.apiErrorMessage = 'errors.email__invalid-credential'
							message = this.$t('errors.email__invalid-credential') as string
						}
					} else if (failState === 'password') {
						this.errors.password = 'errors.password__fail-attempt'
						message = this.$t('errors.password__fail-attempt') as string
					} else if (failState === 'locked') {
						this.errors.password = 'errors.password__account-locked'
						message = this.$t('errors.password__account-locked') as string
					} else if (failState === 'google_signin') {
						this.apiErrorMessage = 'errors.email__belongs_to_google'
						message = this.$t('errors.email__belongs_to_google') as string
					} else {
						this.apiErrorMessage = err.response?.data?.message
						message = this.apiErrorMessage
					}

					GA4service.sendGa4NewFormatEvent({
						event: `authentication.${EVENT_LABEL_LOGIN}`,
						event_label: EVENT_LABEL_LOGIN,
						userId: null as any,
						lang: this.$i18n.locale,
						auth_method: EVENT_LABEL_EMAIL_METHOD,
						status: 'fail',
						message,
						location
					})
				}
			}
		},
		resetErrorMessage() {
			this.apiErrorMessage = ''
		}
	}
})
