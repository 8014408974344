<template>
	<v-card class="message-container" elevation="1">
		<v-icon size="36" :color="status ? 'success' : 'error'">
			{{ status ? 'check_circle' : 'error' }}
		</v-icon>
		<div class="message-text">{{ message }}</div>
	</v-card>
</template>

<script>
import Service from '@/services'

export default {
	name: 'DatagateConsentGrant',
	props: {
		consentCode: {
			type: String,
			required: true,
			default: null
		}
	},
	data() {
		return {
			status: false,
			message: null
		}
	},
	mounted() {
		this.getMessageFromConsentRequest()
	},
	methods: {
		async getMessageFromConsentRequest() {
			const response = await Service.consentGrantRequest(this.consentCode)
			this.message = response.message
			this.status = response.status
		}
	}
}
</script>

<style lang="scss">
.v-sheet.v-card:not(.v-sheet--outlined) {
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.v-application .elevation-3 {
	box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1) !important;
}
.v-application .elevation-2 {
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
}
</style>

<style lang="scss" scoped>
.message {
	&-container {
		margin-top: 4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 1rem;
		padding: 1.5rem 2rem;
		width: 480px;
	}
	&-text {
		font-weight: 500 !important;
		line-height: 20px !important;
		font-size: 18px !important;
		letter-spacing: 0 !important;
		color: #323232;
		text-align: center;
	}
}
</style>
