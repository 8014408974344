
import Vue from 'vue'

export default Vue.extend({
	props: {
		type: {
			type: String,
			default: 'info'
		},
		active: {
			type: Boolean,
			default: false
		},
		text: {
			type: String,
			default: ''
		}
	},
	computed: {
		backgroundColor(): string {
			let color = '#FFE5E5'
			if (this.type === 'success') {
				color = '#EAFAEF'
			}
			return color
		},
		alertIcon(): string {
			const icons = {
				info: 'info',
				error: '$vuetify.icons.opSnackBarError',
				success: '$vuetify.icons.opSnackBarSuccess'
			} as Record<string, string>

			return icons[this.type]
		}
	},

	methods: {
		handleClose() {
			this.$emit('close')
		}
	}
})
