import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const call = async (method: string, url: string, config: AxiosRequestConfig) => {
	const response: AxiosResponse = await axios.request({
		method,
		url,
		withCredentials: true,
		...config
	})
	return response.data
}

export default {
	async get(path: string, params?: Record<string, string>) {
		return await call('get', path, {
			params
		})
	},
	async post(path: string, data: Record<string, string>) {
		return await call('post', path, {
			data
		})
	}
}
