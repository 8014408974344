
import { defineComponent } from 'vue'
import { validateEmail, validatePassword } from '@/utils/validator'
import { getQueryParam } from '@/utils/url'
import AuthService from '@/services'
import AnalyticsService from '@/services/analytics'
import { useStore } from '@/store'
import PageTitle from '@/components/PageTitle.vue'
import PageAction from '@/components/PageAction.vue'
import FormDivider from '@/components/FormDivider.vue'
import TextField from '@/components/TextField.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import AlertBanner from '@/components/AlertBanner.vue'
import TermsConditions from '@/components/TermsConditions.vue'
import OpDialog from '@/components/OpDialog.vue'
import {
	EVENT_LABEL_SignupwithEmail,
	EVENT_LABEL_REGIS_FORM,
	EVENT_LABEL_LOCATION,
	EVENT_LABEL_SIGNUP,
	EVENT_LABEL_EMAIL_METHOD,
	EVENT_LABEL_GOOGLE_METHOD,
	UI_DELAY
} from '@/constants'

const delay = () => new Promise((res) => setTimeout(res, UI_DELAY))

const HUBSPOT_PORTAL_ID = process.env.VUE_APP_HUBSPOT_PORTAL_ID
const HUBSPOT_LIB_URL = process.env.VUE_APP_HUBSPOT_LIB_URL
const HUBSPOT_FORM_ID = process.env.VUE_APP_HUBSPOT_BLOCK_USER

export default defineComponent({
	components: {
		PageTitle,
		PageAction,
		FormDivider,
		TextField,
		PrimaryButton,
		TermsConditions,
		AlertBanner,
		OpDialog
	},
	props: {
		isWebComponent: {
			type: Boolean,
			default: false
		},
		redirectUrl: {
			type: String,
			default: ''
		},
		lang: {
			type: String,
			default: 'id'
		},
		companyNpwp: {
			type: String,
			default: ''
		}
	},
	setup() {
		const store = useStore()
		return { store }
	},
	mounted() {
		if (getQueryParam('blocked_user') === 'true') {
			this.handleOpenDialog()
		}
		this.catchGoogleSSOError()
		if (this.isWebComponent) {
			const locationUrl = window.location.href
			let location = EVENT_LABEL_LOCATION.default
			if (locationUrl.indexOf('gated-content') !== -1) {
				location = EVENT_LABEL_LOCATION.gatedContent
			}
			this.$i18n.locale = this.lang
			this.$gsi.setRedirectUrl(this.redirectUrl)
			this.$gsi.setSource(location)
			this.$gsi.reInitialize()
			this.$gsi.createWCButton(this.$refs.signUpButtonRef, this.$i18n.locale, 'signup_with')
		} else {
			this.$gsi.createButton(this.$refs.signUpButtonRef, this.$i18n.locale)
		}
	},
	watch: {
		'$i18n.locale': {
			async handler(newLocale) {
				this.store.isRendered(false)
				if (this.isWebComponent) {
					this.$gsi.createWCButton(
						this.$refs.signUpButtonRef,
						this.$i18n.locale,
						'signup_with'
					)
				} else {
					this.$gsi.createButton(this.$refs.signUpButtonRef, newLocale)
				}
				await delay()
				this.$forceUpdate()
				this.store.isRendered(true)
			},
			immediate: true,
			deep: true
		}
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
				redirectUrl: '',
				companyNpwp: ''
			},
			errors: {
				email: '',
				password: ''
			},
			isApiError: false,
			signUpButtonState: true,
			apiErrorMessage: '',
			isDialogOpen: false,
			userDomain: getQueryParam('domain')
		}
	},
	computed: {
		isErrorEmail(): boolean {
			return !!this.errors.email
		},
		isErrorPassword(): boolean {
			return !!this.errors.password
		},
		isRendered(): boolean {
			return this.store.reRender
		}
	},
	methods: {
		async handleOpenDialog() {
			const script = document.createElement('script')
			script.src = HUBSPOT_LIB_URL
			document.body.appendChild(script)
			script.addEventListener('load', async () => {
				if (window.hbspt) {
					await window.hbspt.forms.create({
						region: 'na1',
						portalId: HUBSPOT_PORTAL_ID,
						formId: HUBSPOT_FORM_ID,
						target: '#hubspotForm-user-unpermitted-signup'
					})
				}
			})
			this.isDialogOpen = true
		},
		async handleCloseDialog() {
			this.isDialogOpen = false
			this.$router.replace({ query: null })
		},
		catchGoogleSSOError() {
			const GA4service = new AnalyticsService()
			const googleErrorProvide = ['attempt', 'user-not-found', 'user-already-registered']

			const errorHashMessage = (this.$route.query['google-failure'] as string) || ''

			if (googleErrorProvide.includes(errorHashMessage)) {
				this.apiErrorMessage = `errors.${errorHashMessage}`
				this.isApiError = true

				GA4service.sendGa4NewFormatEvent({
					event: `authentication.${EVENT_LABEL_SIGNUP}`,
					event_label: EVENT_LABEL_SIGNUP,
					userId: null as any,
					lang: this.$i18n.locale,
					auth_method: EVENT_LABEL_GOOGLE_METHOD,
					status: 'fail',
					message: this.$t(this.apiErrorMessage) as string,
					location: 'platform_auth'
				})
				setTimeout(() => this.$router.replace({ query: null as any }), 9600)
			}
		},
		validateForm(isEmailValidation: boolean, isPasswordValidation: boolean) {
			this.apiErrorMessage = ''

			if (isEmailValidation) {
				if (!this.form.email.length) this.errors.email = 'errors.email__required'
				else if (!validateEmail(this.form.email))
					this.errors.email = 'errors.email__invalid'
				else this.errors.email = ''
			}

			if (isPasswordValidation) {
				if (!this.form.password.length) this.errors.password = 'errors.password__required'
				else if (!validatePassword(this.form.password))
					this.errors.password = 'errors.password__invalid-length'
				else this.errors.password = ''
			}
		},
		setEmail(val: string) {
			this.form.email = val
			this.validateForm(true, false)
		},
		setPassword(val: string) {
			this.form.password = val
			this.validateForm(false, true)
		},
		async signUpAction() {
			const locationUrl = window.location.href
			let location = EVENT_LABEL_LOCATION.default
			if (locationUrl.indexOf('gated-content') !== -1) {
				location = EVENT_LABEL_LOCATION.gatedContent
			}
			const GA4service = new AnalyticsService()
			this.validateForm(true, true)

			const isFormPassed = !this.isErrorEmail && !this.isErrorPassword
			this.isApiError = false
			this.signUpButtonState = !isFormPassed
			this.form.redirectUrl = this.store.redirectUrl
				? this.store.redirectUrl
				: this.redirectUrl
			this.form.companyNpwp = this.companyNpwp

			if (isFormPassed && !this.isApiError) {
				try {
					const { redirectUrl, user_id } = await AuthService.signUpAction(this.form)
					GA4service.sendGA4EventMarketing({
						event: EVENT_LABEL_SignupwithEmail,
						formId: EVENT_LABEL_REGIS_FORM,
						userId: user_id,
						location
					})

					GA4service.sendGa4NewFormatEvent({
						event: `authentication.${EVENT_LABEL_SIGNUP}`,
						event_label: EVENT_LABEL_SIGNUP,
						userId: user_id,
						lang: this.$i18n.locale,
						auth_method: EVENT_LABEL_EMAIL_METHOD,
						status: 'success',
						message: '',
						location,
						eventCallback: () => {
							window.location.href = redirectUrl
						}
					})
				} catch (err) {
					const failState = err.response?.data?.failState
					if (failState === 'user') {
						const blockRedirect = err.response?.data?.redirectUrl
						if (blockRedirect) {
							window.location.href = blockRedirect
						}
					}
					this.signUpButtonState = true
					this.isApiError = true
					const code = err.response?.data?.code
					if (code && code === '11') {
						this.apiErrorMessage = 'errors.email__already-registered'
					}

					GA4service.sendGa4NewFormatEvent({
						event: `authentication.${EVENT_LABEL_SIGNUP}`,
						event_label: EVENT_LABEL_SIGNUP,
						userId: null as any,
						lang: this.$i18n.locale,
						auth_method: EVENT_LABEL_EMAIL_METHOD,
						status: 'fail',
						message: this.$t(this.errors.email) as string,
						location
					})
				}
			}
		},
		resetErrorMessage() {
			this.apiErrorMessage = ''
		}
	}
})
