
import Vue from 'vue'

export default Vue.extend({
	props: {
		required: {
			type: Boolean,
			default: true
		},
		type: {
			type: String,
			default: 'text'
		},
		inputType: {
			type: String,
			default: 'text'
		},
		placeholder: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		error: {
			type: Boolean,
			default: false
		},
		errorText: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			passwordVisible: false,
			passwordVisibiltyIcons: ['opVisibilityOff', 'opVisibility'],
			passwordToggleType: ['password', 'text']
		}
	},
	computed: {
		isPasswordType(): boolean {
			return this.inputType === 'password'
		},
		textFieldType(): string {
			const idx = +this.passwordVisible
			return this.isPasswordType ? this.passwordToggleType[idx] : this.type
		},
		passwordVisibleIcon(): string {
			const idx = +this.passwordVisible
			return '$vuetify.icons.' + this.passwordVisibiltyIcons[idx]
		},
		isValidValue(): boolean {
			return !this.error && !!this.value
		}
	},
	methods: {
		onChange(val: string): void {
			this.$emit('change', val)
		},
		onKeyup(kbd: KeyboardEvent): void {
			const input = kbd.target as HTMLInputElement
			this.$emit('keyup', input?.value)
		},
		onTogglePasswordVisibility(): void {
			this.passwordVisible = !this.passwordVisible
		}
	}
})
