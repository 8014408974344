import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
	state: () => {
		return {
			redirectUrl: '' as string,
			reRender: true as boolean
		}
	},
	actions: {
		setRedirectUrl(redirectUrl: string) {
			this.redirectUrl = redirectUrl
		},
		isRendered(value: boolean) {
			this.reRender = value
		}
	}
})
