export type DataLayerPayload = {
	event: string
	eventCallback?(): void
}

export type DataLayerPayloadMarketing = DataLayerPayload & {
	formId: string
	userId: number
	location: string
}

export type DataLayerPayloadV2 = {
	event: string
	event_label: string
	userId: number
	lang: string
	location: string
	auth_method: string
	status: string
	message: string
	eventCallback?(): void
}

export default class Analytics {
	private dataLayer?: object[]

	constructor() {
		if (window && window.dataLayer) {
			this.dataLayer = window.dataLayer
		} else {
			throw new Error('dataLayer not initialized')
		}
	}

	sendGA4Event(payload: DataLayerPayload) {
		if (payload.event) {
			this.dataLayer?.push(payload)
			return true
		}
		return false
	}

	sendGA4EventMarketing(payload: DataLayerPayloadMarketing) {
		return this.sendGA4Event(payload)
	}

	sendGa4NewFormatEvent(payload: DataLayerPayloadV2) {
		if (payload.event) {
			this.dataLayer?.push(payload)
			return true
		}
		return false
	}
}
