<template>
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.96 11.0262L9.1933 2.70625C9.07961 2.49292 8.91007 2.31452 8.70281 2.19012C8.49554 2.06572 8.25836 2 8.01663 2C7.7749 2 7.53772 2.06572 7.33046 2.19012C7.1232 2.31452 6.95365 2.49292 6.83997 2.70625L2.03997 11.0262C1.92272 11.2293 1.8611 11.4597 1.86133 11.6942C1.86156 11.9287 1.92363 12.159 2.04129 12.3619C2.15894 12.5647 2.32801 12.733 2.53144 12.8496C2.73487 12.9663 2.96547 13.0272 3.19997 13.0263H12.8C13.0345 13.0272 13.2651 12.9663 13.4685 12.8496C13.6719 12.733 13.841 12.5647 13.9586 12.3619C14.0763 12.159 14.1384 11.9287 14.1386 11.6942C14.1388 11.4597 14.0772 11.2293 13.96 11.0262ZM8.55997 11.4396H7.43997V10.3196H8.55997V11.4396ZM8.55997 9.19958H7.43997V4.93292H8.55997V9.19958Z"
			fill="#CC1D33"
		/>
	</svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'AlertWarningIcon'
})
</script>
