<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.8455 9.05415L14.7273 11.9269C14.7273 11.8814 14.7273 11.8269 14.7273 11.7814C14.7273 11.0581 14.4399 10.3644 13.9285 9.85295C13.417 9.34149 12.7233 9.05415 12 9.05415C11.9455 9.05415 11.9 9.05415 11.8455 9.05415ZM7.93636 9.78143L9.34545 11.1905C9.3 11.3814 9.27273 11.5723 9.27273 11.7814C9.27273 12.5047 9.56006 13.1984 10.0715 13.7099C10.583 14.2214 11.2767 14.5087 12 14.5087C12.2 14.5087 12.4 14.4814 12.5909 14.436L14 15.8451C13.3909 16.1451 12.7182 16.3269 12 16.3269C10.7945 16.3269 9.63832 15.848 8.78588 14.9955C7.93344 14.1431 7.45455 12.987 7.45455 11.7814C7.45455 11.0632 7.63636 10.3905 7.93636 9.78143ZM2.90909 4.75415L4.98182 6.82688L5.39091 7.23597C3.89091 8.41779 2.70909 9.96325 2 11.7814C3.57273 15.7723 7.45455 18.5996 12 18.5996C13.4091 18.5996 14.7545 18.3269 15.9818 17.836L16.3727 18.2178L19.0273 20.8723L20.1818 19.7178L4.06364 3.59961L2.90909 4.75415ZM12 7.23597C13.2055 7.23597 14.3617 7.71487 15.2141 8.56731C16.0666 9.41974 16.5455 10.5759 16.5455 11.7814C16.5455 12.3632 16.4273 12.9269 16.2182 13.436L18.8818 16.0996C20.2455 14.9632 21.3364 13.4723 22 11.7814C20.4273 7.79052 16.5455 4.96325 12 4.96325C10.7273 4.96325 9.50909 5.19052 8.36364 5.59961L10.3364 7.55415C10.8545 7.35415 11.4091 7.23597 12 7.23597Z"
			fill="#999999"
		/>
	</svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'VisibilityOffIcon'
})
</script>
