import '@babel/polyfill'
import Vue from 'vue'
import appOptions from '@/options'
import App from '@/App.vue'
import googleButton from '@/plugins/googleButton'

const subDomain = process.env.NODE_ENV === 'development' ? 'authentication-local' : 'auth'

Vue.use(googleButton, {
	clientId: process.env.VUE_APP_GCLIENT_ID,
	baseUrl: `https://${subDomain}.${process.env.VUE_APP_OP_DOMAIN}`
})

new Vue({
	...appOptions,
	render: (h) => h(App)
}).$mount('#app')
