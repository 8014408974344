export function validateEmail(email: string) {
	/* eslint-disable max-len, no-useless-escape */
	const re =
		/^(([^<>()\[\]\\.,;:+!~`?*%$#/^&\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(email)
}

export function validatePassword(password: string) {
	return password.length >= 8
}
