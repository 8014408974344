export const EVENT_LABEL_EmailLoginSuccess = 'EmailLoginSuccess'
export const EVENT_LABEL_SignupwithEmail = 'SignupwithEmail'
export const EVENT_LABEL_LOGIN_FORM = 'login-form'
export const EVENT_LABEL_REGIS_FORM = 'regis-form'
export const EVENT_LABEL_LOCATION = {
	default: 'platform_auth',
	gatedContent: 'gated_content'
}
export const EVENT_LABEL_SIGNUP = 'sign_up'
export const EVENT_LABEL_LOGIN = 'login'
export const EVENT_LABEL_EMAIL_METHOD = 'email'
export const EVENT_LABEL_GOOGLE_METHOD = 'google'
export const UI_DELAY = 1200
