
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

const domain = process.env.VUE_APP_OP_DOMAIN

export default Vue.extend({
	props: {
		type: {
			type: String,
			default: 'signup'
		}
	},
	data() {
		return {
			tosLink: {
				id: `https://www.${domain}/syarat-ketentuan`,
				en: `https://www.${domain}/en/terms-conditions`
			} as Record<string, string>,
			ppLink: {
				id: `https://www.${domain}/kebijakan-privasi`,
				en: `https://www.${domain}/en/privacy-policy`
			} as Record<string, string>
		}
	},
	computed: {
		agreementsTypes(): Record<string, TranslateResult> {
			return {
				signin: this.$t('buttons.signin'),
				signup: this.$t('buttons.signup')
			}
		},
		agreement(): TranslateResult {
			return this.$t('agreement', { type: this.agreementsTypes[this.type] })
		}
	},
	methods: {
		goToTermsAndCondition() {
			window.open(this.tosLink[this.$i18n.locale])
		},
		goToPrivacy() {
			window.open(this.ppLink[this.$i18n.locale])
		}
	}
})
