<template>
	<v-dialog @input="emitCloseDialog" class="op-dialog" :width="width" :value="isDialogOpen">
		<v-card class="op-dialog__card">
			<v-row class="op-dialog__card-title">
				<slot name="title" />
				<div>
					<v-hover v-slot="{ hover }">
						<v-icon
							class="op-dialog__card-title__icon"
							:class="{ 'op-dialog__card-title__icon-hover': hover }"
							@click="emitCloseDialog"
						>
							close
						</v-icon>
					</v-hover>
				</div>
			</v-row>

			<v-row class="op-dialog__card-body">
				<slot name="body" />
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import { VCard, VRow, VDialog, VHover } from 'vuetify/lib'

export default {
	name: 'OpDialog',
	components: {
		VCard,
		VDialog,
		VHover,
		VRow
	},
	props: {
		isDialogOpen: {
			type: Boolean,
			default: false
		},
		width: {
			type: Number,
			default: 550
		}
	},
	methods: {
		emitCloseDialog() {
			this.$emit('close-dialog')
		}
	}
}
</script>

<style lang="scss">
.op-dialog {
	&__container {
		&-title {
			width: 100%;
			font-family: 'Lexend', sans-serif;
		}

		&-form {
			font-family: 'Lexend', sans-serif;
			border-top: 1px solid #f1f1f1;
			padding: 24px 20px;
			width: 100%;
		}
	}

	&__card {
		border-radius: 12px !important;
		box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1) !important;

		&-title {
			display: flex;
			font-weight: 500;
			font-size: 22px;
			line-height: 32px;
			align-items: center;
			color: #323232;
			justify-content: space-between;
			flex-direction: row;
			padding: 18px 24px;
			flex-wrap: unset;

			&__icon {
				color: #323232 !important;
				&-hover {
					color: #e00000 !important;
				}
			}
		}

		&-body {
			max-height: 600px;
			overflow-y: scroll;
			background: linear-gradient(0deg, rgba(199, 199, 199, 0.08), rgba(199, 199, 199, 0.08)),
				#fdfdfd;
		}
	}
}
</style>
