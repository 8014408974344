import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'

Vue.use(PiniaVuePlugin)

Vue.config.productionTip = process.env.NODE_ENV !== 'production'

const pinia = createPinia()

export default {
	vuetify,
	router,
	i18n,
	pinia
}
